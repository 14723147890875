import { departements } from "../data";
import { ClientData, DepartementData, ReseauData, SecteurData } from "./types";

const jsonNormalizr =
  <T extends object>(dataMapper: (object: any) => T) =>
  (data: any[]) =>
    data.map(dataMapper);

const clientMapper = (object: any): ClientData => ({
  ...object,
  secteur_id: Number(object.secteur_id),
  reseau_id: Number(object.reseau_id),
  departement_id: Number(object.departement_id),
  effectifs: Number(object.effectifs),
  produits: object.produits.split(","),
  modules_opt: object.modules ? object.modules.split(",") : [],
  site_web_url:
    !object.site_web_url || (object.site_web_url as string).startsWith("http")
      ? object.site_web_url
      : "http://" + object.site_web_url,
});

export const clientNormalizr = jsonNormalizr(clientMapper);

const departementMapper = (object: any): DepartementData => ({
  ...object,
  id: Number(object.id),
});

export const departementNormalizr = jsonNormalizr(departementMapper);

const reseauMapper = (object: any): ReseauData => ({
  ...object,
  id: Number(object.id),
});

export const reseauNormalizr = jsonNormalizr(reseauMapper);

const secteurMapper = (object: any): SecteurData => ({
  ...object,
  id: Number(object.id),
});
export const secteurNormalizr = jsonNormalizr(secteurMapper);
