import {
  clientNormalizr,
  departementNormalizr,
  reseauNormalizr,
  secteurNormalizr,
} from "../util/jsonNormalizr";
import { DepartementData } from "../util/types";
import jsonClients from "./clients.json";
import jsonDepartements from "./departements.json";
import jsonReseaux from "./reseaux.json";
import jsonSecteurs from "./secteurs.json";

const clients = clientNormalizr(jsonClients).filter((c) => c.afficher);
const departements = departementNormalizr(jsonDepartements);
const reseaux = reseauNormalizr(jsonReseaux);
const secteurs = secteurNormalizr(jsonSecteurs);

const departementsByRegions = departements.reduce(
  (acc: Record<string, Pick<DepartementData, "id" | "departement">[]>, i) => {
    acc[i.region] = [
      ...(acc[i.region] || []),
      {
        id: i.id,
        departement: i.departement,
      },
    ];

    return acc;
  },
  {},
);

export { clients, departements, departementsByRegions, reseaux, secteurs };
